<template>
    <table id="executives-listing" class="table table-striped dataTable no-footer" role="grid">
        <thead>
            <tr role="row">
                <td>Name</td>
                <td>Region</td>
                <td>Electoral Areas</td>
                <td>Branches</td>
                <td>Total Voters</td>
                <td>Created At</td>
                <td>Updated At</td>
            </tr>
        </thead>

        <tbody>
            <tr role="row" v-for="(constituency, index) in constituencies" :key="index">
                <td>{{ constituency.name }}</td>
                <td>{{ constituency.region ? constituency.region.name : 'N/A' }}</td>
                <td>{{ constituency.electoral_areas.length }}</td>
                <td>{{ numberOfBranches(constituency.electoral_areas) }}</td>

                <td>{{ numberOfVoters(constituency.electoral_areas) }}</td>

                <td>{{ constituency.created_at | formattedDateTime }}</td>
                <td>{{ constituency.updated_at | formattedDateTime }}</td>
                <td>
                    <button class="btn btn-warning btn-action mr-3" @click="$emit('edit', constituency)" data-toggle="popover" data-content="Edit Constituency">
                        <i class="mdi mdi-pencil"></i>
                    </button>

                    <button class="btn btn-danger btn-action" @click="$emit('delete', constituency.id)" data-toggle="popover" data-content="Delete Constituency">
                        <i class="mdi mdi-trash-can"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        props: ['constituencies'],

        methods: {
            numberOfBranches(electoralAreas) {
                return electoralAreas.reduce((total, area) => total += area.branches.length, 0);
            },

            numberOfVoters(electoralAreas) {
                return electoralAreas.reduce((total, area) => {
                    return total + area.branches.reduce((count, branch) => count + branch.registered_voters, 0);
                }, 0);
            }
        }
    }
</script>